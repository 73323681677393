import { ConnectExtension } from "@magic-ext/connect"
// import {
//   InstanceWithExtensions,
//   MagicSDKAdditionalConfiguration,
//   SDKBase,
// } from "@magic-sdk/provider"
import { Chain, normalizeChainId, UserRejectedRequestError } from "@wagmi/core"
import { Magic, MagicSDKAdditionalConfiguration } from "magic-sdk"
// import { Provider } from "ethers"

import { MagicConnector, MagicOptions } from "./magicConnector"
interface MagicConnectOptions extends MagicOptions {
  magicSdkConfiguration?: MagicSDKAdditionalConfiguration
}
export class MagicConnectConnector extends MagicConnector {
  magicSDK?: Magic<ConnectExtension[]>
  // magicSDK?: Magic

  magicSdkConfiguration: MagicConnectOptions["magicSdkConfiguration"]

  constructor(config: { chains?: Chain[]; options: MagicConnectOptions }) {
    super(config)
    this.magicSdkConfiguration = config.options.magicSdkConfiguration
  }

  async connect() {
    try {
      const provider = await this.getProvider()

      if (provider.on) {
        provider.on("accountsChanged", this.onAccountsChanged)
        provider.on("chainChanged", this.onChainChanged)
        provider.on("disconnect", this.onDisconnect)
      }

      // Check if there is a user logged in
      const isAuthenticated = await this.isAuthorized()

      // Check if we have a chainId, in case of error just assign 0 for legacy
      let chainId: number
      try {
        chainId = await this.getChainId()
      } catch (e) {
        chainId = 0
      }

      // if there is a user logged in, return the user
      if (isAuthenticated) {
        return {
          provider,
          chain: {
            id: chainId,
            unsupported: false,
          },
          account: await this.getAccount(),
        }
      } else {
        const account = await this.getAccount()
        return {
          provider,
          chain: {
            id: chainId,
            unsupported: false,
          },
          account,
        }
      }
    } catch (error) {
      throw new UserRejectedRequestError("Something went wrong")
    }
  }

  async getChainId(): Promise<number> {
    const networkOptions = this.magicSdkConfiguration?.network
    if (typeof networkOptions === "object") {
      const chainID = networkOptions.chainId
      if (chainID) {
        return normalizeChainId(chainID)
      }
    }
    throw new Error("Chain ID is not defined")
  }

  getMagicSDK() {
    //: InstanceWithExtensions<SDKBase, ConnectExtension[]> {
    if (!this.magicSDK) {
      this.magicSDK = new Magic(this.magicOptions.apiKey, {
        ...this.magicSdkConfiguration,
        extensions: [new ConnectExtension()],
      })
    }
    return this.magicSDK
  }

  // TODO Once connect API grows, integrate it
  async isAuthorized() {
    const magic = this.getMagicSDK()
    if (magic?.connect) {
      try {
        const info = await magic.connect.getWalletInfo()
        return !!info
      } catch (error) {
        return false
      }
    }
    return false
  }

  async disconnect(): Promise<void> {
    const magic = this.getMagicSDK()
    if (magic?.connect) {
      await magic.connect.disconnect()
    }
  }
}
