// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { NextjsOptions } from "@sentry/nextjs/esm/utils/nextjsOptions"

const enabled = process.env.NODE_ENV === "production" && !!process.env.SENTRY_DSN

console.log("Sentry enabled:", enabled)

const common = (): NextjsOptions => ({
  enabled,
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || "___DSN___",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || "0.03"),
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE || "1.0"),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: process.env.GITHUB_COMMIT,

  environment: process.env.NODE_ENV === "production" ? "production" : "development",

  beforeSend(event, hint) {
    const error = hint?.originalException
    if (error && typeof error !== "string") {
      switch (error.name) {
        case "AuthenticationError":
        case "AuthorizationError":
        case "NotFoundError":
        case "ChunkLoadError":
        case "TwitterRefreshTokenError":
          return null
      }
    }

    console.error("Sentry", typeof error === "string" ? error : error?.name)

    return event
  },
})

export default common
