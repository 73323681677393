import {
  AppProps,
  ErrorBoundary,
  ErrorComponent,
  AuthenticationError,
  AuthorizationError,
  ErrorFallbackProps,
  useQueryErrorResetBoundary,
  RedirectError,
} from "blitz"

import "core-js/actual/object/from-entries"

import { ChakraProvider } from "@chakra-ui/react"
import { theme } from "./_document"
import { ServerError } from "app/errors/ServerError"
import "app/styles/editorParams.css"
import { configureChains, createClient, WagmiConfig } from "wagmi"

import { mainnet, polygon, optimism, arbitrum } from "wagmi/chains"
import { publicProvider } from "wagmi/providers/public"
import { MagicConnectConnector } from "app/lib/magic/magicConnectConnector"
import {
  connectorsForWallets,
  getDefaultWallets,
  RainbowKitProvider,
  Wallet,
} from "@rainbow-me/rainbowkit"
import * as rainbowWallets from "@rainbow-me/rainbowkit/wallets"

const { chains, provider } = configureChains(
  [mainnet, polygon, optimism, arbitrum],
  [publicProvider()]
)

const { wallets: defaultWallets } = getDefaultWallets({
  appName: "Noble Place",
  chains,
})

const magicApiKey = process.env.NEXT_PUBLIC_MAGIC_LINK_KEY
export const rainbowMagicConnector =
  typeof magicApiKey === "string"
    ? ({ chains }: any) => ({
        id: "magic",
        name: "Magic",
        iconUrl: "https://svgshare.com/i/iJK.svg",
        iconBackground: "#fff",
        createConnector: () => {
          const connector = new MagicConnectConnector({
            chains: chains,
            options: {
              apiKey: magicApiKey,
              magicSdkConfiguration: {
                network: {
                  rpcUrl: "https://polygon-rpc.com", // your ethereum, polygon, or optimism mainnet/testnet rpc URL
                  chainId: 137,
                },
              },
              //...Other options (check out full API below)
            },
          })
          return {
            connector,
          }
        },
      })
    : null

const wallets = defaultWallets[0]!.wallets.concat(
  // @ts-ignore
  rainbowMagicConnector ? [rainbowMagicConnector({ chains }) as Wallet] : []
)

const connectors = connectorsForWallets([
  {
    groupName: "Popular",
    wallets,
  },
  {
    groupName: "More",
    wallets: [
      rainbowWallets.argentWallet({ chains }),
      rainbowWallets.trustWallet({ chains }),
      rainbowWallets.omniWallet({ chains }),
      rainbowWallets.ledgerWallet({ chains }),
      rainbowWallets.braveWallet({ chains }),
    ],
  },
])

const wagmiClient = createClient({
  connectors,
  provider,
})

export default function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page)

  const e = new ServerError("hi")

  return (
    <ChakraProvider theme={theme}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} modalSize="compact">
          <ErrorBoundary
            FallbackComponent={RootErrorFallback}
            onReset={useQueryErrorResetBoundary().reset}
            // onError={(error, componentStack) => {
            //   Sentry.captureException(error, { contexts: { react: { componentStack } } })
            // }}
          >
            {getLayout(<Component {...pageProps} />)}
          </ErrorBoundary>
        </RainbowKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authenticated to access this"
      />
    )
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
    )
  }
}
