import { ConnectExtension } from "@magic-ext/connect"
import { Magic } from "magic-sdk"
import { Address, Chain, Connector, normalizeChainId } from "@wagmi/core"
import { ethers, Signer } from "ethers"
import { getAddress } from "ethers/lib/utils"

const IS_SERVER = typeof window === "undefined"
export interface MagicOptions {
  apiKey: string
  accentColor?: string
  isDarkMode?: boolean
  customLogo?: string
  customHeaderText?: string
}

type MagicProvider = Magic["rpcProvider"]

export abstract class MagicConnector extends Connector {
  ready = !IS_SERVER

  readonly id = "magic"

  readonly name = "Magic"

  provider: MagicProvider

  isModalOpen = false

  magicOptions: MagicOptions

  protected constructor(config: { chains?: Chain[]; options: MagicOptions }) {
    super(config)
    this.magicOptions = config.options
  }

  async getAccount(): Promise<Address> {
    const provider = new ethers.providers.Web3Provider(await this.getProvider())
    const signer = provider.getSigner()
    const account = await signer.getAddress()
    if (account.startsWith("0x")) return account as Address
    return `0x${account}`
  }

  async getProvider() {
    if (this.provider) {
      return this.provider
    }
    const magic = this.getMagicSDK()
    this.provider = magic.rpcProvider
    return this.provider
  }

  async getSigner(): Promise<Signer> {
    const provider = new ethers.providers.Web3Provider(await this.getProvider())
    const signer = await provider.getSigner()
    return signer
  }

  async isAuthorized() {
    const magic = this.getMagicSDK()
    try {
      return await magic.user.isLoggedIn()
    } catch (e) {
      return false
    }
  }

  protected onAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) this.emit("disconnect")
    else this.emit("change", { account: getAddress(accounts[0]!) })
  }

  protected onChainChanged(chainId: string | number): void {
    const id = normalizeChainId(chainId)
    const unsupported = this.isChainUnsupported(id)
    this.emit("change", { chain: { id, unsupported } })
  }

  protected onDisconnect(): void {
    this.emit("disconnect")
  }

  async disconnect(): Promise<void> {
    const magic = this.getMagicSDK()
    await magic.user.logout()
  }

  abstract getMagicSDK(): // | InstanceWithExtensions<SDKBase, OAuthExtension[]>
  Magic<ConnectExtension[]>
}
