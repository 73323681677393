import { Document, Html, DocumentHead, Main, BlitzScript /*DocumentContext*/ } from "blitz"
import { mode } from "@chakra-ui/theme-tools"
import { Global, css, CacheProvider } from "@emotion/react"
import { ChakraTheme, ColorModeScript, extendTheme, ThemeConfig } from "@chakra-ui/react"
import createCache from "@emotion/cache"

const emotionCache = createCache({
  nonce: "MYfIRjdcGPZ0aCrChzzlSYwvERjcAtiY8sHZEzZXvk=",
  key: "noble",
})

// import React, { Suspense } from "react"

if (typeof localStorage !== "undefined") {
  localStorage?.removeItem("chakra-ui-color-mode")
}

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}
// 3. extend the theme

export const theme = extendTheme({
  config,
  fonts: { body: "Inter, system-ui, sans-serif", heading: "Inter, system-ui, sans-serif" },
  colors: {
    // for buttons, 200 is dark, 500 is light
    discord: {
      // 50: ,
      50: "rgb(81 98 255 / 20%)",
      100: "#5865F2",
      200: "rgb(101, 114, 255)",
      300: "#a2a9f5",
      400: "#5865F2",
      500: "#5865F2",
      // 600: "#5865F2",
      600: "rgb(65 77 206)",
      700: "#5865F2",
      800: "#5865F2",
      900: "#5865F2",
    },
    nifty: {
      // 50: ,
      50: "#00d8fd",
      100: "#00DBF7",
      200: "#00DAF9",
      300: "#00E7DF",
      400: "#00E7DF",
      500: "#00E7DF",
      600: "#00F2C9",
      700: "#00DFF0",
      800: "#00f7c0",
      900: "#00F9BC",
    },
    twitter: {
      50: "rgb(90 193 255 / 20%)",
    },
    gray: {
      50: "rgb(180 180 180 / 12%)",
    },
    telegram: {
      50: "rgba(162, 212, 236, 0)",
      300: "rgba(162, 212, 236, 0.24)",
    },
  },

  components: {
    Button: {
      baseStyle: (props) => ({
        letterSpacing: "normal",
        // _hover: {
        //   bgColor: props.colorScheme + "."
        // }
        // color: props.colorScheme === "discord" ? "red" : undefined,
        // color: mode("white", "white")(props),
      }),
      // defaultProps: (props) => ({
      // }),

      variants: {
        solid: (props) => ({
          color: ["discord", "nifty"].includes(props.colorScheme)
            ? mode("white", "white")(props)
            : undefined,
        }),
        // outline: (props) => ({
        //   color: props.colorScheme === "discord" ? mode("discord.500", "white")(props) : undefined,
        // }),
      },
    },
  },

  letterSpacings: {
    normal: "-0.03em",
  },
  styles: {
    global: (props) => ({
      "html, body": {
        letterSpacing: "-0.05em",
      },
      body: {
        bg: mode("white", "black")(props),
      },
    }),
  },
} as Partial<ChakraTheme>)

class MyDocument extends Document {
  // Obnly uncomment if you need to customize this behaviour
  // static async getInitialProps(ctx: DocumentContext) {
  //   const initialProps = await Document.getInitialProps(ctx)
  //   return {...initialProps}
  // }

  render() {
    return (
      <Html lang="en">
        <CacheProvider value={emotionCache}>
          <DocumentHead />

          <body>
            <Global
              styles={css`
                html,
                body,
                #__next {
                  min-height: 100%;
                  height: 100%;
                }
              `}
            />
            <ColorModeScript
              nonce="MYfIRjdcGPZ0aCrChzzlSYwvERjcAtiY8sHZEzZXvk="
              initialColorMode={config.initialColorMode}
            />
            <Main />
            <BlitzScript nonce="MYfIRjdcGPZ0aCrChzzlSYwvERjcAtiY8sHZEzZXvk=" />
          </body>
        </CacheProvider>
      </Html>
    )
  }
}

export default MyDocument
