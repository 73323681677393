import SuperJson from "superjson"
import { AuthorizationError } from "blitz"

export class ServerError extends AuthorizationError {
  name = "ServerError"
  constructor(message: string) {
    super(message)
    this.name = "ServerError"
    this.statusCode = 500
  }
}

SuperJson.registerClass(ServerError, { identifier: "ServerError" })
